<template>
    <b-modal id="modal-detail-invoice-VAT" v-model="isOpen" size="lg" centered hide-header :no-close-on-backdrop="true">
        <b-card-header class="banned-background-color">
            <div class="d-flex justify-content-between align-items-center block-header">
                <h4 class="mb-0">
                    {{ $t("golf_view_detail_invocie_VAT") }}
                </h4>
                <ez-icon icon="ezGolf-icon-x" class="cursor-pointer" size="24" @click="hideModal" />
            </div>
        </b-card-header>
        <b-card-body>
            <div style="margin-bottom:5px">
                <ez-icon
                  v-b-tooltip.hover
                  :title="$t('golf_sidebar_checkout_input_payment_person')"
                  icon="ezGolf-icon-edit"
                  class="pointer"
                  size="18"
                  v-if="isSelectListMember"
                  @click="changeInputOrSelectMember"
                />
                <ez-icon
                  v-else
                  v-b-tooltip.hover
                  :title="$t('golf_sidebar_checkout_payment_person')"
                  icon="ezGolf-icon-list"
                  class="pointer"
                  size="18"
                  @click="changeInputOrSelectMember"
                />
                {{ $t("golf_sidebar_checkout_payment_person") }}
                <ez-icon
                  size="18"
                  icon="ezGolf-icon-user"
                />
              </div>
                  <b-row>
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                          <!-- {{ detailTaxInvoice.MemberId }} -->
                          
                            <v-select
                              v-if="isSelectListMember"
                              v-model="detailTaxInvoice.MemberName"
                              :options="listMember"
                              label="fullName"
                              :reduce="fullName => fullName.fullName"
                              :placeholder="$t('golf_sidebar_checkout_payment_person')"
                              :clearable="false"
                            />
                          
                            <b-form-input
                              v-else
                              v-model="detailTaxInvoice.MemberName"
                              :placeholder="$t('golf_sidebar_checkout_input_payment_person')"
                            />

                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col class="m-auto">
                      <b-form-group>
                          <b-form-checkbox v-model="detailTaxInvoice.BuyerNotGetInvoice" @change="changeCheckVisitorNoInvoice(detailTaxInvoice.BuyerNotGetInvoice)">
                            {{ $t('golf_sidebar_merge_visitor_no_invoice') }}
                          </b-form-checkbox>
                        </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                            <v-select
                              v-model="detailTaxInvoice.ClientId"
                              :options="listClient"
                              label="ClientName"
                              :clearable="false"
                              :reduce="ClientName => ClientName.Id"
                              :placeholder="$t('golf_common_client')"
                              :disabled="detailTaxInvoice.BuyerNotGetInvoice"
                              @input="onChangeClient()"
                            />
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <div class="form-group">
                          <b-form-input
                            :disabled="detailTaxInvoice.BuyerNotGetInvoice"
                            v-model="detailTaxInvoice.CompanyName"
                            :placeholder="$t('golf_tax_invoice_company_name')"
                          />
                      </div>
                    </b-col>
                    <b-col md="12">
                      <div class="form-group">
                        <b-form-group>
                            <b-form-input
                              :disabled="detailTaxInvoice.BuyerNotGetInvoice"
                              v-model="detailTaxInvoice.Email"
                              :placeholder="$t('golf_tax_invoice_company_email')"
                            />
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col md="12">
                      <div class="form-group">
                        <b-form-group>
                            <b-form-input
                              :disabled="detailTaxInvoice.BuyerNotGetInvoice"
                              v-model="detailTaxInvoice.CompanyAddress"
                              :placeholder="$t('golf_tax_invoice_company_address')"
                            />
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col md="12">
                      <div class="form-group">
                        <b-form-group>
                            <b-form-input
                              :disabled="detailTaxInvoice.BuyerNotGetInvoice"
                              v-model="detailTaxInvoice.TaxCode"
                              :placeholder="$t('golf_tax_invoice_tax_code')"
                            />
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col md="12">
                      <div class="form-group">
                        <b-row>
                          <b-col cols="12">
                            <b-form-group>
                                <v-select
                                  v-model="detailTaxInvoice.PaymentTypeCode"
                                  :options="listPaymentTypeOpstion"
                                  label="title"
                                  :reduce="option => option.value"
                                  :clearable="false"
                                  :multiple="false"
                                  :placeholder="$t('golf_payment_payment_type')"
                                />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12">
                            <div>
                              <div
                                class="form-group"
                                @mouseleave="handlePaymentType('hide')"
                              >
                                <div
                                  class="form-control input-payment-method"
                                  @click="handlePaymentType('show')"
                                >
                                  <span
                                    v-if="paymentTypeName"
                                    class="text-truncate"
                                  >{{ paymentTypeName }}</span>
                                  <span v-else>{{ $t('golf_payment_payment_method') }}</span>
                                </div>
                                <div
                                  v-if="canShowPaymentType"
                                  class="dropdown-payment-method toggler d-flex flex-wrap"
                                >
                                  <div
                                    v-for="(type, k) in listPaymentType.find(x => x.Code === detailTaxInvoice.PaymentTypeCode).PaymentMethod"
                                    :key="k"
                                    class="paymentType"
                                  >
                                    <div class="paymentType-header">
                                      <div
                                        class="paymentType-header-item text-nowrap"
                                      >
                                        {{ type.Name }}
                                      </div>
                                    </div>
                                    <div class="paymentType-select">
                                      <div
                                        class="paymentType-select-item w-100"
                                      >
                                        <div
                                          v-for="(trans, k) in type.TransactionCode"
                                          :key="k"
                                          class="paymentType-select-method text-nowrap"
                                          @click="handleChoicePayment(type, trans)"
                                        >
                                          {{ trans.Description }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                      
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="form-group">
                        <b-form-input
                          v-model="detailTaxInvoice.RoomNumber"
                          :placeholder="$t('golf_tax_invoice_room_number')"
                        />
                      </div>
                    </b-col>
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                          <b-form-input
                            v-model="detailTaxInvoice.CheckIn"
                            :placeholder="$t('golf_tax_invoice_checkin')"
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                          <b-form-input
                            v-model="detailTaxInvoice.Checkout"
                            :placeholder="$t('golf_tax_invoice_checkout')"
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                            <v-select
                              v-model="detailTaxInvoice.CashierCode"
                              :options="listCashiers"
                              label="label"
                              :reduce="label => label.id"
                              :clearable="false"
                              :multiple="false"
                              :placeholder="$t('golf_sidebar_checkout_cashier')"
                            />
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row v-if="checkModuleEInvocie">
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                          <b-form-input
                            v-model="detailTaxInvoice.FormNo"
                            :placeholder="$t('golf_tax_invoice_formno')"
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                          <b-form-input
                            v-model="detailTaxInvoice.SerialNo"
                            :placeholder="$t('golf_tax_invoice_serialno')"
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                          <b-form-input
                            disabled
                            v-model="detailTaxInvoice.RefNo"
                            :placeholder="$t('golf_tax_invoice_refno')"
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>
        </b-card-body>
        <template #modal-footer>
            <b-button variant="primary" :disabled="isLoading" @click="updateInvoice()">
                {{ $t('golf_common_update') }}
                <b-spinner v-if="isLoading" small label="Loading..." />
            </b-button>
            <b-button variant="secondary" @click="hideModal()">
                Quay lại
            </b-button>
        </template>
    </b-modal>
</template>
<script>
import { invoice } from '@/api/invoice'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    props: [
        'listMember',
        'listClient',
        'listPaymentTypeOpstion',
        'listPaymentType',
        'listCashiers',
        'data'
    ],
    data() {
        return {
            isOpen: false,
            detailTaxInvoice: {
                MemberName: null,
                ClientId: null,
                CompanyName: null,
                CompanyAddress: null,
                Email: null,
                TaxCode: null,
                PaymentTypeCode: null,
                PaymentMethodId: null,
                TransactionCode: null,
                RoomNumber: null,
                CheckIn: null,
                Checkout: null,
                CashierCode: null,
                PaymentBookingDetailService: [],
                SerialNo: null,
                FormNo: null,
                RefNo: null
            },
            isSelectListMember: true,
            paymentTypeName: null,
            canShowPaymentType: false,
            checkModuleEInvocie: JSON.parse(localStorage.getItem('module')).find(x => x == "EINVOICE"),
            isLoading: false,
        }
    },
    watch: {
        isOpen(value) {
            if(value) {
                this.detailInvocie_RIVAT02(this.data.Id)
            } else {
                this.clearData()
            }
        }
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-detail-invoice-VAT')
        },
        onChangeClient() {
            this.detailTaxInvoice.CompanyName = this.listClient.find(x => x.Id == this.detailTaxInvoice.ClientId).InvoiceName ? this.listClient.find(x => x.Id == this.detailTaxInvoice.ClientId).InvoiceName : this.listClient.find(x => x.Id == this.detailTaxInvoice.ClientId).ClientName
            this.detailTaxInvoice.CompanyAddress = this.listClient.find(x => x.Id == this.detailTaxInvoice.ClientId).Address1
            this.detailTaxInvoice.TaxCode = this.listClient.find(x => x.Id == this.detailTaxInvoice.ClientId).TaxCode
            this.detailTaxInvoice.Email = this.listClient.find(x => x.Id == this.detailTaxInvoice.ClientId).Email
        },
        handlePaymentType(type) {
            const isShow = type === 'show'
            this.canShowPaymentType = isShow
        },
        handleChoicePayment(payment, trans) {
            this.detailTaxInvoice.PaymentMethodId = payment.Id
            this.paymentTypeName = `${payment.Name} - ${trans.Description}`
            this.detailTaxInvoice.TransactionCode = trans.TransactionCode
            this.canShowPaymentType = false
        },
        async detailInvocie_RIVAT02(PaymentId) {
            const body = {
                Invoice: {
                    Id: PaymentId
                }
            }
            const response =  await invoice.api_RIVAT02(body)
            if(response.Status == 200) {
                this.detailTaxInvoice = response.Data.Invoice
            }
        },
        async updateInvoice() {
          const body = {
              Invoice: this.detailTaxInvoice
            }
            if(this.detailTaxInvoice.BuyerNotGetInvoice) {
              this.detailTaxInvoice.BuyerNotGetInvoice = true
            } else {
              this.detailTaxInvoice.BuyerNotGetInvoice = false
            }
            await invoice.api_UIVAT04(body).then(res => {
                this.showResToast(res)
                if(res.Status == 200) {
                    this.$emit('event', {type: 'refresh_list_invoice'})
                    this.clearData()
                    this.hideModal()
                }
            })
        },
        changeInputOrSelectMember() {
            this.detailTaxInvoice.MemberName = null
            this.isSelectListMember = !this.isSelectListMember
            if(this.isSelectListMember) {
                if (this.listMember.length == 1) {
                this.detailTaxInvoice.MemberId = this.listMember[0].id
                } else {
                this.detailTaxInvoice.MemberId = null
                }
            } 
        },
        changeCheckVisitorNoInvoice(value) {
          if(value) {
            this.detailTaxInvoice.ClientId = null
            this.detailTaxInvoice.CompanyName = null
            this.detailTaxInvoice.Email = null
            this.detailTaxInvoice.CompanyAddress = null
            this.detailTaxInvoice.TaxCode = null
          }
        },
        clearData() {
            this.detailTaxInvoice = {
                MemberName: null,
                ClientId: null,
                CompanyName: null,
                CompanyAddress: null,
                Email: null,
                TaxCode: null,
                PaymentTypeCode: this.listPaymentTypeOpstion[0].value,
                PaymentMethodId: null,
                TransactionCode: null,
                RoomNumber: null,
                CheckIn: null,
                Checkout: null,
                CashierCode: null,
                PaymentBookingDetailService: [],
                FormNo: this.FormNoDefault,
                SerialNo: this.SerialNoDefault,
                RefNo: null
            }
            this.handleChoicePayment(this.listPaymentType[0].PaymentMethod[0], this.listPaymentType[0].PaymentMethod[0].TransactionCode[0])
            const userData = JSON.parse(localStorage.getItem('userData')) || {}
            const currentUser = userData?.userId || {}
            if (this.listCashiers.filter(x => x.id == currentUser).length) {
                this.detailTaxInvoice.CashierCode = currentUser
            }
        },
    },
}
</script>
<style lang="scss">
#modal-detail-invoice-VAT {
    .paymentType {
            padding: 10px 20px;
            &-header {
                display: flex;
                font-weight: bold;
                &-item {
                    // width: 33%;
                    padding: 5px 0;
                }
            }
            &-select {
                // display: flex;
                // font-weight: normal;
                &-item {
                    // width: 33%;
                    padding: 5px 0;
                    cursor: pointer;
                }
                &-method:hover {
                    background-color: #e2e9f3;
                    color: #114a9f;
                    cursor: pointer;
                }
            }
        }
}
</style>